<template>
	<div class="w-100 landing-page">
		<hero
			id="hero"
			:background="heroData.background"
			:logo="heroData.logo"
			logo-class="mb-4"
			logo-style="width: 50vw; min-width: 18rem;"
			:slogan="heroData.slogan"
			scroll-target="video-section" />
		<div
			id="video-section"
			class="pt-4 bg-white">
			<lazy min-height="300">
				<video-section
					:highlight-color="highlightColor"
					:title="videoData.title"
					:url="videoData.url"
					:preview-img="videoData.previewImg" />
			</lazy>
		</div>
		<lazy min-height="300">
			<about
				:highlight-color="highlightColor"
				:sections="aboutData.sections" />
		</lazy>
		<lazy min-height="300">
			<quote-section
				:highlight-color="highlightColor"
				:loading="loadingReplicatedData"
				:can-register="canRegisterWithThisSponsor" />
		</lazy>
		<lazy min-height="300">
			<contact-section
				:highlight-color="highlightColor"
				:loading="loadingReplicatedData"
				:email="email"
				:image="contactData.image"
				:title="contactData.title"
				:content="contactData.content"
				:cta="contactData.cta"
				:container-style="['xs', 'sm'].includes(windowWidth) ? '' : 'height: 550px !important;'"
				@mail="openMailer" />
		</lazy>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { OPPORTUNITIES_PAGES } from '@/settings/LandingPages';
import { SPECIAL_VALUES } from '@/settings/Replicated';
import { S3_PATH } from '@/settings/Images';
import { LandingPages, Profile } from '@/translations';
import { applyAttrsToHtmlString } from '@/util/HtmlHelper';
import ReplicatedSite from '@/util/ReplicatedSite';
import About from './components/About';
import ContactSection from './components/ContactSection';
import Hero from './components/Hero';
import QuoteSection from './components/QuoteSection';
import VideoSection from './components/VideoSection';
import { RESTRICTED_BY_COUNTRY } from '@/settings/LandingPages2';
import Lazy from '@/components/Lazy';

export default {
	name: 'ProductLandingPage',
	messages: [LandingPages, Profile],
	components: {
		About,
		Hero,
		ContactSection,
		QuoteSection,
		VideoSection,
		Lazy,
	},
	mixins: [WindowSizes],
	data() {
		return {
			replicated: new ReplicatedSite(),
			pageName: 'opportunity-2',
		};
	},
	computed: {
		contentStyles() {
			return {
				'b, ul, h5, h6': {
					style: { color: `${this.highlightColor}` },
				},
				ul: {
					style: { paddingLeft: '1.5rem' },
				},
				'li>span': {
					class: 'font-weight-bold',
				},
				'p, li>span': {
					style: { color: '#73818f' },
				},
				b: {
					class: 'font-weight-bolder',
				},
				'h5, h6': {
					class: 'font-weight-bolder p-0 m-0',
				},
			};
		},
		highlightColor() {
			return OPPORTUNITIES_PAGES[this.pageName].color;
		},
		heroData() {
			const { background, logo, slogan } = OPPORTUNITIES_PAGES[this.pageName].hero;
			const result = {
				background: this.requireAsset(background),
				logo: this.requireAsset(logo),
			};

			if (slogan) {
				result.slogan = this.translate(slogan);
			}

			return result;
		},
		videoData() {
			const {
				title,
				url,
				previewImg,
			} = OPPORTUNITIES_PAGES[this.pageName].video;

			return {
				url,
				title: this.translate(title),
				previewImg: this.requireAsset(previewImg),
			};
		},
		aboutData() {
			const {
				sections,
			} = OPPORTUNITIES_PAGES[this.pageName].about;

			const result = sections.map((section) => {
				const {
					image,
					title,
					content,
					cta,
					reversed,
				} = section;
				const sectionData = {
					image: this.requireAsset(image),
					title: this.translate(title),
					content: applyAttrsToHtmlString(this.translate(content), this.contentStyles),
					reversed: reversed || false,
				};

				if (cta) {
					sectionData.cta = this.transformCta(cta);
				}

				return sectionData;
			});

			return {
				sections: result,
			};
		},
		contactData() {
			const {
				image,
				title,
				content,
				cta,
			} = OPPORTUNITIES_PAGES[this.pageName].contact;

			const ctaData = this.transformCta(cta);
			return {
				image: this.requireAsset(image),
				title: this.translate(title),
				content: this.translate(content),
				cta: ctaData,
			};
		},
		loadingReplicatedData() {
			return !!this.replicated.data.loading;
		},
		canRegisterWithThisSponsor() {
			try {
				return this.replicated.data.response.data.response.user.can_register;
			} catch (error) {
				return false;
			}
		},
		email() {
			try {
				return this.replicated.data.response.data.response.email[0].value;
			} catch (error) {
				return '';
			}
		},
	},
	created() {
		const restricted = RESTRICTED_BY_COUNTRY[this.country] ?? RESTRICTED_BY_COUNTRY.default ?? [];
		if (restricted.includes(this.pageName)) {
			this.$router.replace({ name: 'Store' });
		}

		this.replicated.clear();

		const replicatedUsername = this.$replicated.replicatedId();
		if (replicatedUsername) {
			this.replicated.getReplicatedSiteNav(replicatedUsername).catch(() => {
				this.$replicated.siteOff();
				this.$replicated.siteReplicatedSponsorOff();
				this.$replicated.siteReplicatedPageOff();
			});
		}
	},
	methods: {
		requireAsset(directory) {
			// Allow importing assets with variable directories
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			// require(`@/assets/images/themes/${process.env.VUE_APP_THEME}/landing_pages/${this.pageName}/${directory}`);
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/${this.pageName}/${directory}`;
		},
		transformCta(cta) {
			const result = {
				text: this.translate(cta.text),
			};

			if (cta.url) {
				result.url = cta.url;
			}

			if (cta.target) {
				result.target = cta.target;
			}
			return result;
		},
		makeHighlightStyle(propertyName) {
			if (this.highlightColor) {
				return `${propertyName}: ${this.highlightColor} !important;`;
			}
			return '';
		},
		openMailer() {
			if (this.email) {
				location.href = SPECIAL_VALUES.email(this.email); // eslint-disable-line no-restricted-globals
			}
		},
	},
};
</script>
<style scoped lang="scss">
html, body {
	scroll-behavior: smooth;
}
.landing-page::v-deep {
	.section-header {
		display: block;
		line-height: 3.5rem;
		font-size: 3rem;
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}
	.font-weight-bolder {
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}
}
</style>
